// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-CameraPermissions-Old-Recover-recovery{border-radius:1em;background-color:var(--osdk-color-background-info-pill);color:var(--osdk-color-content-info-pill);font-family:inherit;font-size:var(--onfido-font-size-small);font-weight:600;line-height:1.7142857143em;padding:0 1em}.onfido-sdk-ui-CameraPermissions-Old-Recover-instructions{font-size:var(--onfido-font-size-small);text-align:initial;margin:2.1428571429em 0 1.7142857143em;padding:1.7142857143em 1.7142857143em .8571428571em}@media(--small-viewport)and (--short-viewport){.onfido-sdk-ui-CameraPermissions-Old-Recover-instructions{font-size:var(--onfido-font-size-x-small)}}@media(max-width: 479px){.onfido-sdk-ui-CameraPermissions-Old-Recover-instructions{margin:1.7142857143em 0 .8571428571em}}.onfido-sdk-ui-CameraPermissions-Old-Recover-instructionsTitle{font-weight:600;margin:.5714285714em 0}.onfido-sdk-ui-CameraPermissions-Old-Recover-recovery{padding-top:.2857142857em;padding-bottom:.2857142857em;margin-bottom:10px}.onfido-sdk-ui-CameraPermissions-Old-Recover-steps{padding:0;margin:0}.onfido-sdk-ui-CameraPermissions-Old-Recover-step{margin:0 1.0714285714em .7142857143em}.onfido-sdk-ui-CameraPermissions-Old-Recover-button{margin-left:auto;margin-right:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"recovery": "onfido-sdk-ui-CameraPermissions-Old-Recover-recovery",
	"instructions": "onfido-sdk-ui-CameraPermissions-Old-Recover-instructions",
	"instructionsTitle": "onfido-sdk-ui-CameraPermissions-Old-Recover-instructionsTitle",
	"steps": "onfido-sdk-ui-CameraPermissions-Old-Recover-steps",
	"step": "onfido-sdk-ui-CameraPermissions-Old-Recover-step",
	"button": "onfido-sdk-ui-CameraPermissions-Old-Recover-button"
};
export default ___CSS_LOADER_EXPORT___;
