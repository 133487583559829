// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-CameraPermissions-New-Recover-instructions{font-size:var(--onfido-font-size-small);text-align:initial;margin:0 0 1.7142857143em}@media(--small-viewport)and (--short-viewport){.onfido-sdk-ui-CameraPermissions-New-Recover-instructions{font-size:var(--onfido-font-size-x-small)}}@media(max-width: 479px){.onfido-sdk-ui-CameraPermissions-New-Recover-instructions{margin:0 0 .8571428571em}}.onfido-sdk-ui-CameraPermissions-New-Recover-instructionsTitle{font-weight:600;margin-bottom:1.1428571429em}.onfido-sdk-ui-CameraPermissions-New-Recover-button{margin-left:auto;margin-right:auto}.onfido-sdk-ui-CameraPermissions-New-Recover-strong{font-weight:bold}.onfido-sdk-ui-CameraPermissions-New-Recover-icon{vertical-align:middle;margin-left:.3571428571em}.onfido-sdk-ui-CameraPermissions-New-Recover-icon-no-margin{vertical-align:middle}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"instructions": "onfido-sdk-ui-CameraPermissions-New-Recover-instructions",
	"instructionsTitle": "onfido-sdk-ui-CameraPermissions-New-Recover-instructionsTitle",
	"button": "onfido-sdk-ui-CameraPermissions-New-Recover-button",
	"strong": "onfido-sdk-ui-CameraPermissions-New-Recover-strong",
	"icon": "onfido-sdk-ui-CameraPermissions-New-Recover-icon",
	"icon-no-margin": "onfido-sdk-ui-CameraPermissions-New-Recover-icon-no-margin"
};
export default ___CSS_LOADER_EXPORT___;
