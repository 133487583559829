import { assign, createMachine } from 'xstate'

import { ErrorNames } from '~types/commons'

export const DOCUMENT_DETECTION_TIMEOUT = 10000

export interface Context {
  image: ImageData | undefined
  video: Blob | undefined
  isVideo: boolean
  isAutoCapture: boolean
  canTimeoutToErrorState: boolean
  detectionError: ErrorNames | undefined
  detectionTimer: number
  timer: number
  autocaptureTimeout: number
  side: string
}

const defaultContext: Context = {
  image: undefined,
  video: undefined,
  isVideo: false,
  isAutoCapture: true,
  canTimeoutToErrorState: false,
  detectionError: undefined,
  detectionTimer: 0,
  timer: 0,
  autocaptureTimeout: 0,
  side: 'front',
}

export const autoCaptureMachine = createMachine<Context>(
  {
    id: 'autocapture',
    initial: 'stopped',
    context: defaultContext,
    predictableActionArguments: true,

    on: {
      RESET: {
        target: '.stopped',
        actions: assign(({}) => defaultContext),
      },
      ERROR: {
        target: '.errored',
      },
    },

    states: {
      stopped: {
        on: {
          START: [
            {
              cond: 'isFront',
              target: 'idle',
            },
            {
              target: 'flipping',
            },
          ],
        },
      },

      flipping: {
        after: {
          FLIP_DELAY: {
            target: 'idle',
          },
        },
      },

      idle: {
        after: {
          ERROR_TIMEOUT: [
            {
              target: 'errored',
              cond: 'canTimeoutToErrorState',
            },
            { target: 'idle' },
          ],
        },
        always: [
          {
            cond: 'isAutoCapture',
            actions: ['initTimer', 'initDetectionTimer'],
            target: 'autoCapturing',
          },
        ],
        on: {
          CAPTURE: {
            target: 'capturing',
            actions: 'analyticsManualCaptureStart',
          },
        },
      },

      autoCapturing: {
        after: {
          AUTO_CAPTURE_DELAY: {
            target: 'detecting',
          },
        },
        always: [
          {
            cond: 'isAutoCaptureTimeout',
            actions: ['disableAutoCapture', 'analyticsTimeout'],
            target: 'idle',
          },
        ],
      },

      detecting: {
        invoke: {
          src: 'detectDocument',
          onDone: [
            {
              cond: 'isDocumentDetected',
              actions: 'clearDetectionError',
              target: 'capturing',
            },
            {
              cond: 'isNoDocumentTimeout',
              actions: ['setDetectionError', 'analyticsSetDetectionError'],
              target: 'autoCapturing',
            },
            {
              target: 'autoCapturing',
            },
          ],
          onError: {
            target: 'errored',
          },
        },
      },

      capturing: {
        type: 'parallel',
        states: {
          capturingImage: {
            initial: 'capture',
            states: {
              capture: {
                invoke: {
                  src: 'captureImage',
                  onDone: [
                    {
                      cond: 'isAutoCapture',
                      actions: ['assignImage', 'analyticsAutoCaptureSuccess'],
                      target: 'done',
                    },
                    {
                      actions: ['assignImage'],
                      target: 'done',
                    },
                  ],
                  onError: {
                    target: '#autocapture.errored',
                  },
                },
              },
              done: { type: 'final' },
            },
          },

          capturingVideo: {
            initial: 'checkVideoEnabled',
            states: {
              checkVideoEnabled: {
                always: [
                  { cond: 'isVideo', target: 'capture' },
                  { target: 'done' },
                ],
              },
              capture: {
                invoke: {
                  src: 'captureVideo',
                  onDone: {
                    actions: 'assignVideo',
                    target: 'done',
                  },
                  onError: {
                    target: '#autocapture.errored',
                  },
                },
              },
              done: { type: 'final' },
            },
          },
        },
        onDone: {
          actions: ['analyticsHoldStillPhaseEnded'],
          target: 'captured',
        },
      },

      captured: {
        after: {
          CAPTURED_DELAY: {
            target: 'submitting',
          },
        },
      },

      submitting: {
        type: 'final',
        entry: 'submit',
      },

      errored: {
        on: {
          CAPTURE: {
            target: 'capturing',
            actions: 'analyticsManualCaptureStart',
          },
        },
      },
    },
  },
  {
    actions: {
      disableAutoCapture: assign({
        isAutoCapture: ({}) => false,
      }),

      assignImage: assign({
        image: ({}, { data }) => data.image,
      }),

      assignVideo: assign({
        video: ({}, { data }) => data,
      }),

      setDetectionError: assign({
        detectionError: ({}) => 'DOCUMENT_DETECTION',
      }),

      clearDetectionError: assign({
        detectionError: ({}) => undefined,
        detectionTimer: ({}) => Date.now(),
      }),

      initTimer: assign({
        timer: ({}) => Date.now(),
      }),

      initDetectionTimer: assign({
        detectionTimer: ({}) => Date.now(),
      }),

      submit: () => Promise.resolve(),
    },
    guards: {
      isFront: ({ side }) => side === 'front',
      isAutoCapture: ({ isAutoCapture }) => isAutoCapture,
      isVideo: ({ isVideo }) => isVideo,
      isDocumentDetected: ({}, { data }) => !!data,
      isNoDocumentTimeout: ({ timer }) =>
        Date.now() - timer > DOCUMENT_DETECTION_TIMEOUT,
      isAutoCaptureTimeout: ({ detectionTimer, autocaptureTimeout }) =>
        Date.now() - detectionTimer > autocaptureTimeout,
      canTimeoutToErrorState: ({ canTimeoutToErrorState }) =>
        canTimeoutToErrorState,
    },
    delays: {
      ERROR_TIMEOUT: 80000,
      AUTO_CAPTURE_DELAY: 100,
      CAPTURED_DELAY: 2000,
      FLIP_DELAY: 2000,
    },
  }
)
