import {
  ActiveVideoCapture,
  LivenessError,
  TrackingEvent,
} from '@onfido/active-video-capture'
import { h, FunctionComponent, Fragment } from 'preact'
import { useMemo, useState } from 'preact/hooks'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { localised } from '~core/localisation'
import type { WithLocalisedProps } from '~core/localisation/types'
import type {
  WithPermissionsFlowProps,
  WithTrackingProps,
  WithFailureHandlingProps,
} from '~types/hocs'
import {
  CombinedActions,
  FaceCapture as ActiveVideoCapturePayload,
} from '~types/redux'
import type { StepComponentBaseProps } from '~types/routers'
import { CameraProps } from '~types/camera'
import { WebcamProps } from 'react-webcam'
import { addDeviceRelatedProperties } from '~utils'
import { randomId } from '~utils/string'
import { getDeviceInfo } from '~utils/camera'
import withFailureHandling from 'components/Camera/withFailureHandling'
import FaceNotDetected from './FaceNotDetected'
import withCrossDeviceWhenNoCamera from 'components/Capture/withCrossDeviceWhenNoCamera'
import captureScreenPerformance from '~core/PerformanceAnalytics/helpers/captureScreenPerformance'
import { StepOptionFace } from '~types/steps'
import { SdkMetadata } from '~types/commons'
import { getFaceDetectionConfiguration } from './utils'
import useSdkConfigurationService from '~core/SdkConfiguration/useSdkConfigurationService'
import Spinner from 'components/Spinner'
import { Header } from './Header'
import withTheme from '../Theme'
import { ToggleFullScreen } from 'components/FullScreen'
import withPermissionsFlowOld from 'components/CameraPermissions/Old/withPermissionsFlow'
import withPermissionsFlowNew from 'components/CameraPermissions/New/withPermissionsFlow'

type Props = StepComponentBaseProps & {
  onUserMedia: () => void
} & CameraProps &
  WebcamProps &
  WithLocalisedProps &
  WithFailureHandlingProps &
  WithPermissionsFlowProps &
  WithTrackingProps

const ActiveVideo: FunctionComponent<Props> = (props) => {
  const {
    nextStep,
    back,
    translate,
    trackScreen,
    actions,
    mobileFlow,
    hasGrantedPermission,
    onFailure,
    steps,
    currentStepType,
    enterpriseFeatures,
  } = props
  const [activeVideoError, setActiveVideoError] = useState<
    LivenessError | Error | null
  >()
  const { motion_capture } = useSdkConfigurationService()
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch<Dispatch<CombinedActions>>()

  const [sdkMetadata, setSdkMetadata] = useState<SdkMetadata>({})

  const faceDetectionConfiguration = useMemo(
    () => getFaceDetectionConfiguration(),
    []
  )

  const faceStep = steps.find(({ type }) => type === currentStepType)
  const recordMotionAudio = !!(faceStep?.options as StepOptionFace)
    ?.recordMotionAudio

  const onSuccess = (event: { videoPayload: Blob }) => {
    const activeVideoCaptureData: ActiveVideoCapturePayload = {
      method: 'face',
      variant: 'motion',
      blob: event.videoPayload,
      id: randomId(),
      sdkMetadata: addDeviceRelatedProperties(sdkMetadata, mobileFlow),
    }

    dispatch(actions.createCapture(activeVideoCaptureData))

    nextStep()
  }

  const onError = (error: LivenessError | Error) => {
    if (error instanceof Error && onFailure) {
      // Under the hood, Motion depends on `react-webcam 7+` which emits DOMException
      // errors. They'll bubble up to `withFailureHandling` or `withPermissionFlow` to
      // show the appropriate error screen.
      onFailure(error)
    } else {
      // Other errors are business-logic errors, like `FACE_DETECTION_TIMEOUT`. In
      // such case, a specific error screen is displayed in place of the capture
      // component.
      setActiveVideoError(error)
    }
  }

  const onUserMedia = (stream: MediaStream) => {
    setSdkMetadata(getDeviceInfo(stream))
    props.onUserMedia()
  }

  const onLoad = () => setIsLoading(false)

  const track = (
    event: TrackingEvent,
    properties?: Record<string, unknown>
  ): void => {
    captureScreenPerformance.onScreenChangeStart()
    trackScreen(event, properties)
  }

  if (activeVideoError === LivenessError.FACE_DETECTION_TIMEOUT) {
    return (
      <FaceNotDetected
        restart={() => setActiveVideoError(null)}
        translate={translate}
        trackScreen={trackScreen}
      />
    )
  } else if (activeVideoError) {
    console.error(`Unsupported error: ${activeVideoError}`)
  }

  if (!process.env.ACTIVE_VIDEO_LIVENESS) {
    throw new Error('ACTIVE_VIDEO_LIVENESS env var was not set')
  }

  const WrappedHeader = withTheme(Header)

  const Loader = () => (
    <WrappedHeader
      back={back}
      title={translate('generic.loading')}
      // TODO: Needs translations
      // subtitle="This should take less than a minute"
      disableNavigation={true}
    >
      <Spinner floating={false} />
    </WrappedHeader>
  )

  // See: https://github.com/preactjs/preact/issues/2748
  return (
    <Fragment>
      <ActiveVideoCapture
        translate={translate}
        track={track}
        onError={onError}
        onSuccess={onSuccess}
        onUserMedia={onUserMedia}
        onLoad={onLoad}
        hasGrantedPermission={!!hasGrantedPermission}
        loader={<Loader />}
        assetsUrl={process.env.ACTIVE_VIDEO_LIVENESS}
        recordAudio={recordMotionAudio}
        webmMimeTypes={
          motion_capture?.video_settings?.webm_mime_type_preference
        }
        {...faceDetectionConfiguration}
      />
      {!isLoading && <ToggleFullScreen />}
    </Fragment>
  )
}

export const ActiveVideoOldPermissions = localised(
  withCrossDeviceWhenNoCamera(
    withFailureHandling(withPermissionsFlowOld(ActiveVideo))
  )
)

export const ActiveVideoNewPermissions = localised(
  withCrossDeviceWhenNoCamera(
    withFailureHandling(withPermissionsFlowNew(ActiveVideo))
  )
)
