// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-CameraPermissions-New-Recover-listRow{display:-webkit-box;display:-webkit-flex;display:flex;margin-bottom:.7142857143em}.onfido-sdk-ui-CameraPermissions-New-Recover-numberedBullet{width:1.7142857143em;height:1.7142857143em;min-width:1.7142857143em;min-height:1.7142857143em;border:.1428571429em solid rgb(var(--ods-color-content-main));border-radius:.8571428571em;text-align:center;margin-right:.7142857143em}.onfido-sdk-ui-CameraPermissions-New-Recover-list{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"listRow": "onfido-sdk-ui-CameraPermissions-New-Recover-listRow",
	"numberedBullet": "onfido-sdk-ui-CameraPermissions-New-Recover-numberedBullet",
	"list": "onfido-sdk-ui-CameraPermissions-New-Recover-list"
};
export default ___CSS_LOADER_EXPORT___;
