// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".onfido-sdk-ui-CameraPermissions-New-Primer-image{height:auto;width:100%;font-size:var(--onfido-font-size-small);display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;-webkit-box-flex:2;-webkit-flex:2 1 2.9090909091em;flex:2 1 2.9090909091em;min-height:2.9090909091em;max-width:9.6435714286em;width:100%;padding:.7142857143em;margin:auto;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-transform:translateZ(0);transform:translateZ(0)}.onfido-sdk-ui-CameraPermissions-New-Primer-twoImages{max-width:19.4285714286em;-webkit-box-align:center;-webkit-align-items:center;align-items:center}@media(max-width: 479px){.onfido-sdk-ui-CameraPermissions-New-Primer-twoImages{max-width:19.2857142857em}}.onfido-sdk-ui-CameraPermissions-New-Primer-iconAllow{background-color:transparent;background-repeat:no-repeat;background-size:100%;background-position:center;height:8.5em;width:100%}@media(max-width: 479px){.onfido-sdk-ui-CameraPermissions-New-Primer-iconAllow{background-size:contain;-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_animation_duration": "200ms",
	"image": "onfido-sdk-ui-CameraPermissions-New-Primer-image",
	"twoImages": "onfido-sdk-ui-CameraPermissions-New-Primer-twoImages",
	"iconAllow": "onfido-sdk-ui-CameraPermissions-New-Primer-iconAllow"
};
export default ___CSS_LOADER_EXPORT___;
